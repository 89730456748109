.app__aboutus {
  position: relative;
  background: var(--color-black);
}

.app__calender-h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  /* letter-spacing: 0.04em; */
  text-transform: uppercase;
  line-height: 40px;
  padding-bottom: 2rem;
  /* font-size: 20px; */
}

.app__aboutus-content {
  width: 100%;
  z-index: 2;
  flex-direction: column;
  text-align: center;
}

.app__aboutus-content_about {
  flex: 1;
  display: grid;
  /* padding: 2rem; */
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
  color: var(--color-golden);
}

.grid_left {
  text-align: right;
  font-weight: 1000;
}

.grid_right {
  text-align: left;
}

.app__aboutus-content_about p {
  margin: 2rem 0;
  color: var(--color-grey);
}

.p__opensans_calender {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  font-display: under;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}

@media screen and (min-width: 2000px) {
  .app__aboutus-content_about p {
    margin: 4rem 0;
  }
}
