.app__contact {
  background: var(--color-black);
  padding: 3rem;
  /* margin-top: 3rem;
  margin-bottom: 3rem; */
  border-radius: var(--border-radius);
  flex: 0.6;
}

.app__contact_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  box-sizing: border-box;
}

.app__form_input {
  background-color: var(--color-black);
  border-radius: 3px;
  margin-bottom: 1rem;
  resize: none;
  box-shadow: 2px 2px 9px 0.5px var(--color-white);
}

.custom__alert {
  background-color: var(--color-black);
  color: var(--color-white);
}

@media screen and (max-width: 950px) {
  .app__contact {
    flex: 1;
  }
}
