#tuition .app__tuition-content {
  /* background-color: var(--color-black);
  box-shadow: 0px 0px 7px 3px var(--color-black);
  border-radius: var(--border-radius); */
  padding: 2rem;
  flex: 0.6;
}

@media screen and (max-width: 950px) {
  #tuition .app__tuition-content {
    flex: 1;
  }
}
