.section__padding {
  padding: 6rem 7rem;
}

a:hover {
  /* text-decoration: underline; */
  color: var(--color-golden);
}

a:active {
  text-decoration: underline;
  color: var(--color-golden);
}

/* unvisited link */
a:link {
  /* color: var(--color-golden); */
  text-decoration: underline;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex__center_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url("./assets/bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__bg__piano-keys {
  background: url("./assets/welcome.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  /* background-attachment: fixed; */
}

.app__bg__welcome {
  background: url("./assets/keys.jpg");
  background-position: center;
  background-size: cover;
  /* filter: saturate(0); */

  /* background-repeat: repeat; */
  /* background-attachment: fixed; */
}

.app__bg__hands {
  background: url("./assets/handsonpiano.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  /* background-attachment: fixed; */
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 10vh;
  scroll-margin-top: 4rem;
}

.app__wrapper_info {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  scroll-margin-top: 4rem;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  max-width: 600px;
  /* max-height: 00px; */
}

.app__wrapper_img-reverse {
  flex: 1;
  justify-content: flex-start;
  /* margin-right: 2rem; */
}

.app__wrapper_img img {
  width: 80%;
}

.custom__button {
  background-color: var(--color-crimson);
  color: var(--color-black);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  border-radius: 1px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 0.5rem;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}

ul {
  margin-left: 4rem;
}
.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 55px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.subtitle__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-size: 15px;
  line-height: 22.2px;
  letter-spacing: 0.6em;
  text-transform: capitalize;
  margin-top: 1rem;
}

.subtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 40px;
  line-height: 63.2px;
  letter-spacing: 0.03em;
}

.center_text {
  text-align: center;
}

.spoon__img {
  width: 45px;
}

.soft__background {
  background-color: var(--color-black);
  box-shadow: 0px 0px 7px 3px var(--color-black);
  border-radius: var(--border-radius);
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1500px) {
  .app__wrapper {
    min-height: 85vh;
  }
  .app__wrapper {
    min-height: 85vh;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
    max-width: 400px;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 2rem 2rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}
